import AR from '../assets/images/flags/AR.png';
import FR from '../assets/images/flags/FR.png';
import EN from '../assets/images/flags/EN.png';
import SP from '../assets/images/flags/SP.png';
import TR from '../assets/images/flags/TR.png';

export const screensList = [
  { idScreen: 1, number_screen: 1, name_screen: 'Ecran 1' },
  { idScreen: 2, number_screen: 2, name_screen: 'Ecran 2' },
  { idScreen: 3, number_screen: 3, name_screen: 'Ecran 3' },
  { idScreen: 4, number_screen: 4, name_screen: 'Ecran 4' },
  { idScreen: 5, number_screen: 5, name_screen: 'Ecran 5' },
  { idScreen: 6, number_screen: 6, name_screen: 'Ecran 6' },
  { idScreen: 7, number_screen: 7, name_screen: 'Ecran 7' },
  { idScreen: 8, number_screen: 8, name_screen: 'Ecran 8' },
];
export const availaibleTypeOrder = [
  {
    name: 'A emporté',
    schedule: [
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 0,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 1,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 2,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 3,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 4,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 5,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 6,
      },
    ],
  },
  {
    name: 'Sur Place',
    schedule: [
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 0,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 1,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 2,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 3,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 4,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 5,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 6,
      },
    ],
  },
];
export const printers = [
  {
    idPrinter: 2,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: 'imprimante chamas 1',
    number_ticket: 1,
    ip_address: '192.168.1.201',
    type: 'Réseau',
  },
  {
    idPrinter: 3,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 4,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 5,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: 'imprimante chamas 2',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 6,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 7,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 8,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 9,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 10,
    width_ticket: 48,
    type_printer: 'label',
    name: 'imprimante label',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 11,
    width_ticket: 48,
    type_printer: 'label',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 12,
    width_ticket: 48,
    type_printer: 'label',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 13,
    width_ticket: 48,
    type_printer: 'label',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
];
export const tags = [
  {
    number_tag: 1,
    deleted: false,
    background_catalog: 'backgound_1.jpg',
    logo_tag: 'logo_1.png',
    idTag: 1,
    name_tag: 'tag1',
  },
  {
    number_tag: 2,
    deleted: false,
    background_catalog: 'backgound_2.jpg',
    logo_tag: 'logo_2.png',
    idTag: 2,
    name_tag: 'tag2',
  },
  {
    number_tag: 3,
    deleted: false,
    background_catalog: 'backgound_3.jpg',
    logo_tag: 'logo_3.png',
    idTag: 3,
    name_tag: 'tag3',
  },
  {
    number_tag: 4,
    deleted: false,
    background_catalog: 'backgound_4.jpg',
    logo_tag: 'logo_4.png',
    idTag: 4,
    name_tag: 'tag4',
  },
  {
    number_tag: 5,
    deleted: false,
    background_catalog: 'backgound_5.jpg',
    logo_tag: 'logo_5.png',
    idTag: 5,
    name_tag: 'tag5',
  },
  {
    number_tag: 6,
    deleted: false,
    background_catalog: 'backgound_6.jpg',
    logo_tag: 'logo_6.png',
    idTag: 6,
    name_tag: 'tag6',
  },
];

export const currencyCodeToLocale = {
  USD: 'en-US',
  MAD: 'fr-Fr',
  EUR: 'fr-FR',
  GBP: 'en-GB',
  JPY: 'ja-JP',
  AUD: 'en-AU',
  CNY: 'zh-CN',
  INR: 'en-IN',
  CHF: 'de-CH',
  AED: 'ar-AE',
  MXN: 'es-MX',
  BRL: 'pt-BR',
  ZAR: 'en-ZA',
  SGD: 'en-SG',
  HKD: 'en-HK',
  RUB: 'ru-RU',
  SAR: 'ar-SA',
  SEK: 'sv-SE',
  NZD: 'en-NZ',
  NOK: 'no-NO',
  DKK: 'da-DK',
  KRW: 'ko-KR',
  THB: 'th-TH',
  TRY: 'tr-TR',
  MYR: 'en-MY',
  IDR: 'id-ID',
  PHP: 'en-PH',
  CAD: 'en-CA',
  ARS: 'es-AR',
  CLP: 'es-CL',
  COP: 'es-CO',
  VND: 'vi-VN',
  EGJ: 'ar-EG',
  IQD: 'ar-IQ',
  ILS: 'he-IL',
  JOD: 'ar-JO',
  KWD: 'ar-KW',
  LBP: 'ar-LB',
  OMR: 'ar-OM',
  QAR: 'ar-QA',
  TND: 'ar-TN',
  MOP: 'zh-MO',
  BHD: 'ar-BH',
  NGN: 'en-NG',
  PKR: 'en-PK',
  AOA: 'pt-AO',
  BGN: 'bg-BG',
  HRK: 'hr-HR',
  CZK: 'cs-CZ',
  HUF: 'hu-HU',
  PLN: 'pl-PL',
  RON: 'ro-RO',
  RSD: 'sr-RS',
  LKR: 'si-LK',
  UAH: 'uk-UA',
  BDT: 'bn-BD',
  AMD: 'hy-AM',
  GEL: 'ka-GE',
  AZN: 'az-AZ',
  KZT: 'kk-KZ',
  UZS: 'uz-UZ',
  KGS: 'ky-KG',
  TJS: 'tg-TJ',
  BYN: 'be-BY',
  MNT: 'mn-MN',
  KHR: 'km-KH',
  LAK: 'lo-LA',
  MMK: 'my-MM',
  NIO: 'es-NI',
  CRC: 'es-CR',
  PAB: 'es-PA',
  PEN: 'es-PE',
  DOP: 'es-DO',
  TWD: 'zh-TW',
  IRR: 'fa-IR',
  TMT: 'tk-TM',
  YER: 'ar-YE',
  UYU: 'es-UY',
  PYG: 'es-PY',
  BOB: 'es-BO',
  VEF: 'es-VE',
  HNL: 'es-HN',
  GTQ: 'es-GT',
  SVC: 'es-SV',
  BZD: 'en-BZ',
  BMD: 'en-BM',
  XPF: 'fr-PF',
  FJD: 'en-FJ',
  PGK: 'en-PG',
  STD: 'pt-ST',
};
export const timeTable = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];
export const timeZoneMap = [
  {
    code: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Toronto',
    GMTOfsset: 'UTC -04:00',
  },
  {
    code: 'MA',
    countryName: 'Morocco',
    timeZone: 'Africa/Casablanca',
    GMTOfsset: 'UTC +01:00',
  },
  {
    code: 'NC',
    countryName: 'New Caledonia',
    timeZone: 'Pacific/Noumea',
    GMTOfsset: 'UTC +11:00',
  },
  {
    code: 'FR',
    countryName: 'France',
    timeZone: 'Europe/Paris',
    GMTOfsset: 'UTC +02:00',
  },
  {
    code: 'SA',
    countryName: 'Saudi Arabia',
    timeZone: 'Asia/Riyadh',
    GMTOfsset: 'UTC +03:00',
  },
  {
    code: 'PF',
    countryName: 'French Polynesia',
    timeZone: 'Pacific/Gambier',
    GMTOfsset: 'UTC -09:00',
  },
  {
    code: 'CH',
    countryName: 'Switzerland',
    timeZone: 'Europe/Zurich',
    GMTOfsset: 'UTC +02:00',
  },
];

export const headTable = [
  {
    id: 1,
    label: 'name',
    title: 'Client',
  },
  {
    id: 2,
    label: 'lastOrder',
    title: 'Dernière commande ',
  },
  {
    id: 3,
    label: 'nbOrders',
    title: "Nombre d'ordres",
  },
  {
    id: 5,
    label: 'orderTypes',
    title: "Types d'ordres",
  },
  {
    id: 6,
    label: 'orderSources',
    title: "Sources d'ordre",
  },
];

export const displayFlag = (codeLangue) => {
  let img;
  switch (codeLangue) {
    case 'ar':
      img = AR;
      break;
    case 'en':
      img = EN;
      break;
    case 'es':
      img = SP;
      break;
    case 'fr':
      img = FR;
      break;
    case 'tr':
      img = TR;
      break;
    default:
      img = null;
      break;
  }
  return img;
};
export const orderPriority = {
  "Sur Place": 1,
  "A Emporter": 2,
  "En Livraison": 3,
  "Platform": 4,
  "Drive": 5
};
export const orderSizesPriority = {
  "junior": 1,
  "senior": 2,
  "mega": 3,
  "size1": 4,
  "size2": 5,
  "size3": 6,
  "size4": 7,
  "size5": 8,
  "size6": 9,
  "size7": 10,
};


export const typeCommande = [
  {
    type_order: 'Sur Place',
    tariff: 1,
  },
  {
    type_order: 'A Emporter',
    tariff: 1,
  },
  {
    type_order: 'En Livraison',
    tariff: 1,
  },
  {
    type_order: 'Platform',
    tariff: 1,
  },
  {
    type_order: 'Drive',
    tariff: 1,
  },
  {
    type_order: 'Sur Place',
    tariff: 2,
  },
  {
    type_order: 'A Emporter',
    tariff: 2,
  },
  {
    type_order: 'En Livraison',
    tariff: 2,
  },
  {
    type_order: 'Platform',
    tariff: 2,
  },
  {
    type_order: 'Drive',
    tariff: 2,
  },
  {
    type_order: 'Sur Place',
    tariff: 3,
  },
  {
    type_order: 'A Emporter',
    tariff: 3,
  },
  {
    type_order: 'En Livraison',
    tariff: 3,
  },
  {
    type_order: 'Platform',
    tariff: 3,
  },
  {
    type_order: 'Drive',
    tariff: 3,
  },
];
export const productKeysInfrensh={
  "display_image_in_kitchen": "Afficher l'image en cuisine",
  "alias_product": "Alias du produit",
  "available_caisse": "Disponible en caisse",
  "price": "Prix",
  "hide_price_in_borne": "Masquer le prix sur la borne",
  "best_sale": "Meilleure vente",
  "product_loyalty": "Produit fidélité",
  "price_point": "Point de prix",
  "available_takeaway": "Disponible à emporter",
  "marque": "Marque",
  "hidden_ubereat": "Caché sur Uber Eats",
  "hidden_caisse": "Caché en caisse",
  "idCat": "ID de catégorie",
  "free_price": "Prix libre",
  "additional_sale_atSpot": "Vente additionnelle sur place",
  "product_ubereat": "Produit Uber Eats",
  "hidden_online": "Caché en ligne",
  "moment_product": "Produit du moment",
  "available_on_the_spot": "Disponible sur place",
  "additional_sale_take_away": "Vente additionnelle à emporter",
  "unavailable_online": "Indisponible en ligne",
  "top_product": "Produit phare",
  "valid_caisse": "Valide en caisse",
  "later": "Plus tard",
  "promo_basket": "Panier promotionnel",
  "sold_out": "Épuisé",
  "additional_sale": "Vente additionnelle",
  "many_size": "Plusieurs tailles",
  "unit": "Unité",
  "hidden_borne": "Caché sur la borne",
  "hide_name_in_borne": "Masquer le nom sur la borne",
  "daily_stock": "Stock journalier",
  "hidden": "Caché",
  "enable_in_opening_mode": "Activer en mode ouverture",
  "different_price": "Prix différent"
}

export const modules = [
    {
      name: 'Mes Cartes',
      color: '#A854F9',
      backgroundColor: '#a854f91a',
    },
    {
      name: 'Mes Produits',
      color: '#7CB6FF',
      backgroundColor: 'rgba(124, 182, 255, 0.15)',
    },
    {
      name: 'Générer carte',
      color: '#F5771A',
      backgroundColor: 'rgba(245, 119, 26, 0.15)',
    },
  ];