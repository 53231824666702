import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    changeLog: null,
    changeLogs: {},
}
export const changeLogs = createSlice({
    name:"changeLogs",
    initialState,
    reducers:{
        setChangeLogs: (state,action)=>{
           state.changeLogs=action.payload
        },
    }
})
export const {
  setChangeLogs
} = changeLogs.actions
export default changeLogs.reducer